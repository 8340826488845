<template>
  <div :class="[prefixCls, className ? className : '']" :style="style">
    <header
      :class="[`${prefixCls}-header `, headerClass || '', !hideRight ? 'cursor-pointer' : '']"
      @click="handleShowContent"
    >
      <div :class="[`${prefixCls}-header-title`, titleClass || '']" v-if="title">
        {{ title }}
        <div class="inline-flex items-center text-fgrey font-normal ml-2" v-if="tips">
          <ExclamationCircleOutlined class="mx-2" />
          <div v-html="tips"></div>
          <a class="text-blue text-xs" @click.stop="handleLinkClick">{{ linkText }}</a>
        </div>
      </div>
      <slot name="title"></slot>
      <div :class="`${prefixCls}-header-right`">
        <slot name="right"></slot>
        <img
          v-if="!hideRight"
          :class="[
            `${prefixCls}-header-right__image`,
            show ? `${prefixCls}-header-right__image-close` : '',
          ]"
          :src="newDown"
          alt=""
        />
      </div>
    </header>

    <transition>
      <section
        v-show="show"
        :style="{
          padding: '15px',
          ...contentStyle,
        }"
        :class="['card-content', !show ? 'up' : 'down']"
      >
        <slot></slot>
      </section>
    </transition>
  </div>
</template>
<script lang="ts" setup>
  import { ref, watch } from 'vue'

  import type { PropType } from 'vue'
  import { useDesign } from 'framework/hooks/web/useDesign'
  import new_down from 'framework/assets/images/new_down.png'
  import { ExclamationCircleOutlined } from '@ant-design/icons-vue'

  const props = defineProps({
    title: {
      type: [String, Array] as PropType<string>,
      default: '',
    },
    className: {
      type: [String, Array] as PropType<string>,
      default: '',
    },
    style: {
      type: Object as PropType<Recordable>,
      default() {
        return {
          marginBottom: '8px',
        }
      },
    },
    contentStyle: {
      type: Object as PropType<Recordable>,
      default() {
        return {
          padding: '16px',
        }
      },
    },
    contentShow: {
      type: Boolean,
      default: true,
    },
    hideRight: {
      type: Boolean,
      default: false,
    },
    headerClass: {
      type: [String, Array] as PropType<string>,
      default: '',
    },
    titleClass: {
      type: [String, Array] as PropType<string>,
      default: '',
    },
    tips: {
      type: String, // 提示
      default: '',
    },
    link: {
      type: String, // 跳转链接
      default: '',
    },
    linkText: {
      type: String, // 跳转文案
      default: '',
    },
  })
  const { prefixCls } = useDesign('basic-card')
  const show = ref<boolean>(true)
  const newDown = ref(new_down)
  const emit = defineEmits(['linkClick', 'handleShowContent'])
  const handleLinkClick = () => {
    if (props.link) {
      window.location.href = props.link
    }
    emit('linkClick')
  }
  watch(
    () => props.contentShow,
    (val: boolean) => {
      show.value = val
    },
    { immediate: true, deep: true },
  )
  function handleShowContent() {
    if (props.hideRight) return
    show.value = !show.value
    emit('handleShowContent')
  }
  function openAllCard() {
    if (props.hideRight) return
    show.value = true
  }
  defineExpose({
    openAllCard,
  })
</script>
<style lang="less" scoped>
  @prefix-cls: ~'@{namespace}-basic-card';

  .@{prefix-cls} {
    background: @white;
    position: relative;
    // z-index: 99;
    &-header {
      background: @white;
      padding: 0 16px;
      height: 33px;
      // line-height: 33px;
      border-bottom: 1px solid #e9e9e9;
      font-size: 14px;
      color: @color333;
      display: flex;
      justify-content: space-between;
      position: relative;
      align-items: center;

      &-title {
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: bold;
        color: @color333;
        align-items: center;
        display: inline-flex;
      }

      &::before {
        content: '';
        width: 2px;
        height: 14px;
        background: @primary-color;
        position: absolute;
        left: -0;
        top: 50%;
        transform: translateY(-50%);
      }

      &-right {
        display: flex;
        align-items: center;

        &__image {
          cursor: pointer;
          transform: rotate(-90deg);
          width: 22px;
          height: 21px;

          &-close {
            transform: rotate(0deg);
            width: 21px;
            height: 22px;
          }
        }
      }
    }

    &-content {
      background: @white;
      max-height: 0;
      transition: max-height 0.2s linear;
      overflow: hidden;
      will-change: max-height;
      height: unset;

      &.up {
        max-height: 0;
      }

      &.down {
        max-height: 2000px;
        overflow: auto;
      }
    }
  }
</style>
