<template>
  <div :class="[prefixCls]">
    <div class="flex justify-between items-center">
      <div
        :class="[
          'flex items-center title-container',
          props.status == 1 || props.status == 3 ? '' : 'active',
        ]"
      >
        <span class="">{{ props.titleKey }}</span>
        <span>{{ props.title }}</span>
      </div>
      <slot name="right"></slot>
    </div>
    <div class="flex items-end justify-between">
      <div class="pl-[40px]">
        <slot name="content"></slot>
      </div>
      <a-button
        :disabled="props.disabled"
        v-if="props.status == 1 || props.status == 3"
        type="primary"
        @click="handleSureClick"
        :loading="false"
      >
        确认
      </a-button>
      <a-button :disabled="props.disabled" v-else @click="handleUnSureClick">取消确认</a-button>
    </div>
  </div>
</template>
<script lang="ts" setup>
  import { useDesign } from 'framework/hooks/web/useDesign'
  const { prefixCls } = useDesign('progress-card')

  interface Props {
    title: string
    titleKey: number
    status: number
    disabled: boolean
  }
  const emit = defineEmits(['buttonClick'])

  const props = withDefaults(defineProps<Props>(), {
    title: '',
    titleKey: 1,
    status: 1,
    disabled: false,
  })

  function handleSureClick() {
    emit('buttonClick')
  }
  function handleUnSureClick() {
    emit('buttonClick')
  }
</script>
<style lang="less" scoped>
  @prefix-cls: ~'@{namespace}-progress-card';

  .@{prefix-cls} {
    .title-container {
      > span:first-child {
        width: 32px;
        height: 32px;
        background: #f2f3f5;
        margin-right: 8px;
        font-size: 14px;
        font-weight: 700;
        color: #86909c;
        line-height: 32px;
        text-align: center;
        border-radius: 50%;
        display: inline-block;
      }

      > span:last-child {
        font-size: 16px;
        font-weight: 700;
        color: #333333;
      }

      &.active {
        > span:first-child {
          color: #ffffff;
          background: #165dfe;
        }

        > span:last-child {
          color: #165dfe;
        }
      }
    }
  }
</style>
