import { withInstall } from 'framework/utils'
import card from './src/card.vue'
import progressCard from './src/ProgressCard.vue'

export const Card = withInstall(card)
export const ProgressCard = withInstall(progressCard)

// import { App } from 'vue'

// import { installComponent } from 'framework/components/install'
// import type { SheepUIOptions } from 'framework/utils/_utils/global-config'

// export default {
//   install(app: App, options?: SheepUIOptions) {
//     // installComponent(app, Card, options)
//     // installComponent(app, ProgressCard, options)
//   },
// }
